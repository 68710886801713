.typing{
    color: #30343c;
}

.typing:after {
    content: '';
    display: inline-block;
    height: 1.19em;
    width: 2px;
    margin-left: 2px;
    vertical-align: text-top;
    background-color: #30343c;
    animation: cursor 500ms infinite alternate;
    position: relative;
    top: -2px;
}

@keyframes cursor {
    0% { opacity: 0; }
    50% { opacity: 0; }
    70% { opacity: 1; }
    100% { opacity: 1; }
}
